<template>
  <div>
    <NavBar />
    <div class="dialogWrapper">
      <div class="dialogWindow">
        <div class="messageWrapper">
          <div class="left">
            <div class="personName">
              <p class="name">Mirko Rasic</p>
              <p>
                Sajt za internet prodaju poljoprivrednih proizvoda, mašina i opreme. Iskoristite naš sajt da oglasite vaš proizvod ili da stupite u kontakt sa ostalim oglašivačima.
              </p>
            </div>
          </div>

          <div class="right">
            Sajt za internet prodaju poljoprivrednih proizvoda, mašina i opreme. Iskoristite naš sajt da oglasite vaš proizvod ili da stupite u kontakt sa ostalim oglašivačima.
            <div class="messIcn">
              <v-text-field v-model="messageField" outlined dense placeholder="Napiši..."></v-text-field>
              <div class="icon">
                <v-icon @click="sent">mdi-send</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "../Components/NavBar.vue";
import Footer from "../Components/Footer.vue";

export default {
  name: "AddProduct",
  data() {
    return {
      messageField: "",
    };
  },
  components: {
    NavBar,
    Footer,
  },
  methods: {
    sent() {},
  },
};
</script>

<style lang="scss" scoped>
.messageWrapper {
  display: flex;
  .left {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 714px;
    background-color: #fff;
    margin-bottom: 25px;
    .personName {
      padding: 0.5rem;
      border-bottom: 1px solid #fff;
      .name {
        font-weight: bold;
      }
    }
  }

  .right {
    padding: 1rem;
    width: 75%;
    position: relative;
  }
  .messIcn {
    position: absolute;
    display: flex;
    width: 85%;
    bottom: 10px;
  }
  .icon {
    margin: 0.5rem;
    width: 2px;
  }
}
.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogWindow {
  border-radius: 4px;
  margin: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
}

.tab {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.activeTab {
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #ffff;
  background-color: #1c7947;
  border-bottom: 1px solid #ccc;
}

.activeTab:first-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #1c7947;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.activeTab:last-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #1c7947;
}

.activeTab p,
.tab p {
  margin: 0;
  font-size: 1rem;
  word-break: keep-all;
}

.v-card__subtitle,
.v-card__title {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .dialogWindow {
    width: 1440px;
    min-height: 714px;
  }
  .mainDiv {
    width: 30%;
  }

  .dialogWindow {
    margin: 0 auto;
  }

  .tabWrapper {
    flex-direction: row;
  }

  .tab {
    width: 33%;
    border-bottom: 1px solid grey;
  }
  .activeTab {
    width: 33%;
  }
  .activeTab:first-child {
    border-top-right-radius: 4px;
  }
  .activeTab:last-child {
    border-top-right-radius: 4px;
  }
}
</style>
